 
$(document).ready(function(){
  $('[data-toggle="tooltip"]').tooltip();   
  
  //left navigation
  $('.left-nav-icon').on('click', function () {
    $(".left-nav").toggleClass('left-nav-collapse');
    $(".navbar-icon-menu").toggleClass('navbar-icon-close');
    $(".side-navbar").toggleClass('col-lg-1');
    $(".right-section").toggleClass('col-lg-11');
});




$('.left-nav ul li a').click(



    function (e) {
        e.preventDefault();
        $('a').removeClass('active');
        $(this).addClass('active');
    });


    //Start:: Drawer js------------------------------//
    var drawerButton = $("#addAccount");
    var drawerRight = $("#addNewAccount");
    var closebutton = $("#close-dw");
    drawerRight.css('display', 'none');
    drawerButton.click(function (e) {
        drawerRight.drawer('toggle');
        drawerRight.css('display', 'block');
        closebutton.focus();
        $('.backdrop-space').append('<div class="backdrop"></div>');
        e.preventDefault();
        // $('.drawer-right').animate({
        //     right : "0%"
        // },2000)
    });
    closebutton.click(function () {
        $('.backdrop-space .backdrop').remove();
        drawerRight.css('display', 'none');
        $(".backdrop-space .backdrop").css('display', 'none');
        drawerButton.focus();
        e.preventDefault();
    });



    //DeligateToSomeone drawer
    $("#deligateToSomeone").css('display', 'none');
    $("#deligate").click(function (e) {
        $("#deligateToSomeone").drawer('toggle');
        $("#deligateToSomeone").css('display', 'block');
        $("#close-deligateToSomeone").focus();
        $('.backdrop-space').append('<div class="backdrop"></div>');
        e.preventDefault();
    });
    $("#close-deligateToSomeone").click(function () {
        $('.backdrop-space .backdrop').remove();
        $("#deligateToSomeone").css('display', 'none');
        $(".backdrop-space .backdrop").css('display', 'none');
        $("#deligate").focus();
        e.preventDefault();
    });



    //Comment drawer
    $("#addNewComment").css('display', 'none');
    $("#addComment").click(function (e) {
        $("#addNewComment").drawer('toggle');
        $("#addNewComment").css('display', 'block');
        $("#close-addNewComment").focus();
        $('.backdrop-space').append('<div class="backdrop"></div>');
        e.preventDefault();
    });
    $("#close-addNewComment").click(function () {
        $('.backdrop-space .backdrop').remove();
        $("#addNewComment").css('display', 'none');
        $(".backdrop-space .backdrop").css('display', 'none');
        $("#addComment").focus();
        e.preventDefault();
    });

});
$(window).bind('orientationchange', function (e) {
  onOrientationChange();
});

// Initial execution if needed
onOrientationChange();
function onOrientationChange() {

  switch (window.orientation) {
   
    case 0:
      $('#OrientationShow').css('display', 'block');
      $('#OrientationShow').modal('show');

      break;
    default:
      $('#OrientationShow').modal('hide');
      break;
  }
}
// $("#cartPopover").click(function(event){
//   event.stopPropagation();
//   $(".filter-popover-container").toggle();
 
// });
// $(".filter-popover-container").on("click", function (event) {
//   event.stopPropagation();
// });

// $(document).on("click", function () {
//   $(".filter-popover-container").hide();
// }); 
// $("#filterClose").on("click", function () {
//   $(".filter-popover-container").hide();
// }); 
